/* Header styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding: 1rem 2rem;
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    height: 4rem;
  }
  
  /* Logo Styling */
  .login-logo-header {
    height: 2.5rem;
    object-fit: contain;
    cursor: pointer;
  }
  
  /* Right-side container */
  .right-container {
    display: flex;
    align-items: center;
    gap: 1rem; /* Space between balance box and button */
  }
  
  /* Account Balance Container */
  .account-balance-container {
    display: flex;
    align-items: center;
    border: 1px solid #D1DFFF;
    padding: 0.6rem 1rem;
    border-radius: 0.5rem;
    font-family: Figtree, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 15.4px;
    color: #455CBD;
    white-space: nowrap;
    height: 2.5rem;
    display: none;
  }
  
  /* Text inside Account Balance */
  .account-text {
    margin-right: 0.5rem;
  }
  
  /* Amount Styling */
  .account-amount {
    font-weight: 700;
  }
  
  /* Login button styles */
  .login-button-header {
    background: #455CBD;
    color: #FCFDFF;
    font-family: Figtree, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    border: none;
    padding: 0.6rem 1.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    white-space: nowrap;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    min-width: 6rem;
    max-width: 8rem;
  }
  
  .login-button:hover {
    background: #374a9b;
  }
  