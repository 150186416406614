/* Transaction Container */
.transaction-container {
  width: 80%; /* Centering the table */
  margin: 0 auto;
  padding: 1rem;
}

/* Header Row */
.transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.transaction-title {
  font-family: Figtree, sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
  color: #455CBD;
}

.filter-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* Table */
.transaction-table-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table thead {
  background: #BCD0FF;
}

.transaction-table th {
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 600; /* Increased weight for better visibility */
  line-height: 18px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1A2771;
  padding: 1rem;
  width: 25%; /* Ensuring equal column width */
}

.transaction-table td {
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1A2771;
  padding: 1rem;
  width: 25%; /* Ensuring alignment with headers */
}

.transaction-table tbody tr {
  background: #FFFFFF;
  border-bottom: 1px solid #E9E9E9; /* Slightly increased thickness */
}

/* Pagination */
.transaction-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 1.5rem; */
  padding-top: 1rem;
  /* border-top: 1px solid #E9E9E9; */
}

.pagination-info {
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #1A2771;
}

.pagination-buttons {
  display: flex;
  gap: 0.75rem;
  cursor: pointer;
}

.pagination-btn {
  background: #FFFFFF;
  border: 1px solid #CEDEFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 8px 16px;
  border-radius: 8px;
  color: #1A2771;
  font-size: 14px;
  cursor: pointer;
}

/* Prevent hover effect */
.pagination-btn:hover {
  background: #FFFFFF !important;
  border: 1px solid #CEDEFF !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.filter-icon {
  width: 3%;
  /* max-width: 8rem; */
  height: auto;
  display: none;
}

/* Loading Effect */
.table-wrapper {
  position: relative;
}

.table-wrapper.loading {
  opacity: 0.7;
  pointer-events: none;
  user-select: none;
}

/* Loading Overlay */
.loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

/* Modern Spinner */
.modern-spinner {
  width: 36px;
  height: 36px;
  border: 4px solid rgba(70, 130, 180, 0.3);
  border-left-color: #455CBD;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
