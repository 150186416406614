/* LoginPage.css */

/* Full-page background container */
.table-container {
    position: relative;
    min-height: 100vh;
    background-color: #F6F9FF;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    overflow: hidden;
    z-index: 1;
  }
  
  /* Background image overlay */
  .table-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Increased width for zoom-in effect */
    height: 100%; /* Increased height for zoom-in effect */
    object-fit: cover;
    opacity: 0.06; /* 6% opacity */
    transform: rotate(40deg) scale(1.6); /* Zoom-in effect using scale */
    z-index: -1;
  }
  

