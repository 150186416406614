/* Tab Container Styling */
.tab-container {
    display: flex;
    justify-content: center;
    background: #ECF2FF;
    padding: 0.2rem;
    border-radius: 8px;
  }
  
  .tab-wrapper {
    display: flex;
    gap: 1rem;
  }
  
  /* Tab Buttons */
  .tab-button {
    padding: 0.8rem 1rem;
    font-family: Figtree, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    color: #1A2771;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: font-weight 0.3s ease;
    text-decoration-skip-ink: none;
    position: relative;
    width: 15rem;
  }
  
  /* Active and Hover Tab Styling */
  .tab-button:hover,
  .tab-button.active {
    font-weight: 700;
  }
  
  /* Underline for Active Tab */
  .tab-underline {
    position: absolute;
    bottom: -4px;
    left: 42%;
    transform: translateX(-50%);
    width: 13rem;
    height: 4px;
    background: #17226C;
    border-radius: 10px 10px 0px 0px;
  }

  .tab-logo {
    width: 22%;
    /* max-width: 8rem; */
    height: auto;
  }

  .tab-logo-visa {
    width: 15%;
    /* max-width: 8rem; */
    height: auto;
  }
  
  .margin-left-logo {
    margin-right: 0.7rem;
  }
  