/* Login card styling */
.login-card {
  position: relative;
  background: #F6F9FF;
  padding: 2rem;
  border-radius: 12px 0px 0px 0px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 90%; /* Ensuring responsiveness */
  max-width: 400px; /* Capped max-width for larger screens */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .login-card {
    width: 80%;
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .login-card {
    width: 95%;
    padding: 1rem;
  }
}

/* Error styling */
.error-message {
  color: red;
  font-size: 0.875rem; /* Small text */
  margin-top: 0.25rem;
}

.error-border {
  border-color: red !important;
}

/* Logo Styling */
.login-logo {
  width: 30%;
  max-width: 8rem;
  height: auto;
}


.visa-logo {
  width: 20%;
  max-width: 8rem;
  height: auto;
}

/* Login Heading */
.login-title {
  font-family: Figtree, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.8rem;
  text-align: center;
  color: #1A2771;
  margin-bottom: 0.5rem;
}

/* Form styling */
.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group label {
  font-family: Figtree, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #1A2771;
  margin-bottom: 0.5rem;
}

.input-field,
.login-button,
.create-button {
  width: 100%;
  height: 2.8rem; /* Unified height for all elements */
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-field {
  border: 1px solid #CEDEFF;
  outline: none;
  transition: border-color 0.3s ease;
  color: #1A2771;
}

.input-field::placeholder {
  color: #A0AEC0; /* Light gray placeholder text */
}

.input-field:focus {
  border-color: #99bbff;
}

/* Login Button */
.login-button {
  background-color: #455CBD;
  color: #FCFDFF;
  border: none;
  font-family: Figtree, sans-serif;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 1rem;
}

.login-button:hover {
  background-color: #374a9b;
}

/* Create Button */
.create-button {
  background-color: transparent;
  color: #455CBD;
  border: 1px solid #455CBD;
  font-family: Figtree, sans-serif;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.create-button:hover {
  background-color: #455CBD;
  color: #FCFDFF;
}


.payment-message {
  max-width: 26rem;  /* Fixed width */
  /* background-color: #ffecec;   */
  color: #d8000c;  /* Dark red text */
  padding: 8px;
  /* border: 1px solid #d8000c; */
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
  overflow: hidden;  /* Ensures no unwanted scrolling */
  white-space: nowrap;  /* Keeps text in one line */
}

.message-content {
  display: block;
  white-space: nowrap;
  overflow-x: auto;  /* Enables only horizontal scrolling */
  overflow-y: hidden;  /* Prevents vertical scrollbar */
  scrollbar-width: thin;  /* For Firefox */
  -ms-overflow-style: none;  /* For IE/Edge */
  padding-bottom: 0.5rem;
}

/* Custom scrollbar for WebKit browsers */
.message-content::-webkit-scrollbar {
  height: 2px;  /* Thin horizontal scrollbar */
}

.message-content::-webkit-scrollbar-thumb {
  background-color: #d8000c;  /* Red scrollbar */
  border-radius: 3px;
}

.message-content::-webkit-scrollbar-track {
  background: #ffecec;  /* Light red background */
}
